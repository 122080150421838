import poolHouseImg from "../../assets/pool-house.png";
import { Container } from "./styles";

export function SectionPiscina() {
  return (
    <Container>
      <img src={poolHouseImg} alt="Pool House" />
    </Container>
  );
}

import ScrollAnimation from "react-animate-on-scroll";
import gradeSvg from "../../assets/elements/grade.svg";
import lobbyImg from "../../assets/lobby-2.png";
import localizationImg from "../../assets/novo-local.png";
import { DescriptionBlock } from "../DescriptionBlock";
import { Container, Content } from "./styles";

export function SectionLocalizacaoPrivilegiada() {
  return (
    <Container>
      <img src={gradeSvg} alt="grade vertical" className="grade-vertical" />
      <img src={lobbyImg} alt="lobby" className="lobby" />
      <Content>
        <div className="description-content">
          <ScrollAnimation animateIn="animate__fadeIn">
            {/* <h2>Localização Privilegiada</h2> */}
          </ScrollAnimation>
          <p>
            O Lux Design Living está localizado no Umarizal, com acesso à Pedro Álvares Cabral.
          </p>
        </div>
        <div className="footer-content">
          <ScrollAnimation animateIn="animate__fadeInRight">
            <img
              src={localizationImg}
              alt="soares carneiro com pedro alvares cabral"
              className="img-fluid mapa"
            />
          </ScrollAnimation>
          <div className="mt-3 mt-md-0 mb-0 mb-xl-5">
            <DescriptionBlock
              strong="Entre em grande estilo"
              text="Sinta-se bem desde a entrada"
            />
          </div>
        </div>
      </Content>
    </Container>
  );
}

import { FirstSectionContainer } from "./styles";

import logoQuadra from "../../../../assets/logos/logo-quadra.svg";

export function FirstSection() {
  return (
    <FirstSectionContainer>
      <div className="first-section-content-container">
        <img src={logoQuadra} />
        <div className="infos-container">
          {/* <h1>+Quadra</h1>
          <div className="address-container">
            <div className="individual-info-container">
              <h2>QUADRA STORE BATISTA CAMPOS</h2>
              <span>PE. Eutíquio, 1612, Batista Campos</span>
            </div>
            <div className="individual-info-container">
              <h2>QUADRA STORE UMARIZAL</h2>
              <span>Av. Pedro Álvares Cabral, 1300</span>
            </div>
            <div className="individual-info-container">
              <h2>QUADRA STORE JOSÉ MALCHER</h2>
              <span>Av José Malcher, Nazaré</span>
            </div>
            <div className="individual-info-container">
              <h2>QUADRA STORE BERNAL DO COUTO</h2>
              <span>Rua Bernal do Couto, Umarizal</span>
            </div>
          </div> */}
        </div>
      </div>
    </FirstSectionContainer>
  );
}

import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 17rem;
  display: flex;

  padding: 12px;

  @media (max-width: 1200.98px) {
    justify-self: center;
    align-items: center;
  }
  .animated .comodidade-img {
    margin-right: 0.7rem;
    width: 3rem;
    height: 3rem;
  }
  div {
    h3 {
      font-family: "Gilroy Extra";
      font-size: 1rem;
      color: #848484;
      text-align: left;
    }
    p {
      color: #848484;
      font-size: 0.8rem;
    }
  }
`;

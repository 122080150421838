import { Icon } from "@iconify/react";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import { useFetchByContrato } from "../../services/useFetchByContrato";
import { navProps, navLink } from "../Header";
import { PopoverContent, ResponsiveNavContainer } from "./styles";

type responsiveNavMenuProps = {
  isScrollOnTop: boolean;
};

export function ResponsiveNavMenu({ isScrollOnTop }: responsiveNavMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data } = useFetchByContrato<navProps>("/nav");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <ResponsiveNavContainer
        onClick={handleClick}
        aria-describedby={id}
        isScrollOnTop={isScrollOnTop}
      >
        <Icon icon="bi:list" color="#1891f1" width="32" height="32" />
      </ResponsiveNavContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <PopoverContent>
          <Icon icon="bi:x-lg" className="close-button" onClick={handleClose} />
          {data?.navLinks?.map((navLink: navLink) => (
            <a href={navLink.slug} className="nav-link" key={navLink.link}>
              {navLink.link}
            </a>
          ))}
        </PopoverContent>
      </Popover>
    </>
  );
}

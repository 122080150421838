import styled from "styled-components";

import fachadaPng from "../../assets/fachada.png";

export const Container = styled.div`
  width: 100%;
  background-image: url(${fachadaPng});
  background-repeat: no-repeat;
  background-position: center;
`;
export const ContainerSwiper = styled.div`
  padding: 8rem 1rem;
`;

import { DescriptionBlock } from "../DescriptionBlock";
import { Container } from "./styles";

export function SectionCoworking() {
  return (
    <Container>
      <div>
        <DescriptionBlock text="Um espaço confortável e bem idealizado, com sala de reunião reservada e estrutura multifuncional." />
      </div> 
    </Container>
  );
}

import { SectionComodidades } from "../SectionComodidades";
import { SectionCoworking } from "../SectionCoworking";
import { SectionFachada } from "../SectionFachada";
import { SectionLocalizacaoPrivilegiada } from "../SectionLocalizacaoPriveligiada";
import { SectionConforto } from "../SectionConforto";
import { SectionPiscina } from "./../SectionPiscina/index";
import { SectionGaleria } from "./../SectionGaleria/index";
import { SectionFormulario } from "../SectionFormulario";

export function Main() {
  return (
    <main>
      <SectionLocalizacaoPrivilegiada />
      <SectionPiscina />
      <SectionConforto />
      <SectionCoworking />
      <SectionComodidades />
      <SectionFachada />
      <SectionGaleria />
      <SectionFormulario />
    </main>
  );
}

import exampleSignatureImg from "../../assets/testimonials/signature.png";
import couceiroeRubim from "../../assets/testimonials/couceiro.png";
import benedito from "../../assets/testimonials/benedito.png";
import fernandaMarques from "../../assets/testimonials/fernanda-marques.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.scss";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function SwipperTestimonials() {
  return (
    <>
      <Swiper navigation={true} loop={true} spaceBetween={16} className="SwipperTestimonials">
        <SwiperSlide>
          <div className="SwiperContent">
            <img src={couceiroeRubim} alt="" className="img-photo" />
            <div>
              <h3>Couceiro &amp; Rubim</h3>
              <p>
                <span>Projeto Arquitetônico</span>
              </p>
              <p>
                "Para fazer um projeto verdadeiramente contemporâneo fomos buscar o que existe de mais moderno em todo mundo no conceito de morar bem."
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="SwiperContent">
            <img src={fernandaMarques} alt="" className="img-photo" />
            <div>
            <h3>Fernanda Marques</h3>
              <p>
                <span>Projeto de interiores das áreas comuns</span>
              </p>
              <p>
                "Buscamos um padrão de rusticidade elegante, capaz de harmonizar os ambientes, externos ou internos, com toda a contemporaneidade do edifício. A ideia foi ampliar a percepção sensorial dos usuários, contribuindo para um cotidiano mais natural e harmônico."
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="SwiperContent">
            <img src={benedito} alt="" className="img-photo" />
            <div>
            <h3>Benedito Abbud</h3>
              <p>
                <span>Projeto de paisagismo</span>
              </p>
              <p>
                "O projeto paisagístico enaltece os sentidos humanos através de floríferas que tingirão a paisagem com suas cores, plantas aromáticas que espalharão perfume aos ambientes, e frutíferas para as crianças e os pássaros que farão a trilha sonora dos jardins. As áreas externas de lazer foram pensadas como um percurso de sensações e surpresas, lembrando que em paisagismo, a surpresa traz a sensação de beleza."
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

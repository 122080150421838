import styled from "styled-components";

export const FirstSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 17.875rem;
  background-color: #1e2127;

  .first-section-content-container {
    width: 80%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    gap: 8rem;

    @media only screen and (max-width: 520px) {
      gap: 2rem;
      padding: 2rem;
      justify-content: center;
      text-align: center;
    }
  }

  .infos-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    h1 {
      color: rgb(168, 172, 182);
      font-weight: 500;
      font-size: 2rem;
      font-family: "Gilroy Light", Verdana, sans-serif;
    }
  }

  .address-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .individual-info-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    h2 {
      color: rgb(255, 255, 255);
      font-weight: 400;
      font-size: 0.875rem;
      font-family: "Gilroy Light", Verdana, sans-serif;
      margin-bottom: 0;
    }

    span {
      color: rgb(168, 172, 182);
      font-weight: 400;
      font-size: 0.875rem;
      font-family: "Gilroy Light", Verdana, sans-serif;
    }
  }

  img {
    height: 6.375rem;
  }
`;

// 1262 x 286

import { PhoneContainer } from "./styles";

// import feed1 from '../../../../assets/phone-mockup/feed/1.jpg'
// import feed2 from '../../../../assets/phone-mockup/feed/2.jpg'
import icon1 from "../../../../assets/phone-mockup/icons/instagram-grid-alt.svg";
import icon2 from "../../../../assets/phone-mockup/icons/instagram-reels-alt.svg";
import icon3 from "../../../../assets/phone-mockup/icons/instagram-tag-alt.svg";
import bottomBar1 from "../../../../assets/phone-mockup/icons/instagram-home-alt.svg";
import bottomBar2 from "../../../../assets/phone-mockup/icons/instagram-search-alt.svg";
import bottomBar3 from "../../../../assets/phone-mockup/icons/instagram-add-alt.svg";
import bottomBar4 from "../../../../assets/phone-mockup/icons/instagram-heart-alt.svg";
import bottomBar5 from "../../../../assets/phone-mockup/novo-instagram-resized.webp";

type PhoneProps = {
  numOfPublications: number;
  numOfFollowers: number;
  numOfFollowing: number;
};

export function Phone({
  publications,
  followers,
  following,
  images,
}: {
  publications: string;
  followers: string;
  following: string;
  images: string[];
}) {
  return (
    <PhoneContainer>
      <div className="phone-mockup">
        <div className="phone-mockup-back">
          <div className="phone-mockup-left-side">
            <div className="phone-mockup-antena"></div>
            <div className="phone-mockup-button top"></div>
            <div className="phone-mockup-button"></div>
            <div className="phone-mockup-button bottom"></div>
            <div className="phone-mockup-antena bottom"></div>
          </div>

          <div className="phone-mockup-bottom">
            <div className="phone-mockup-antena"></div>

            <div className="bottom-speaker">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className="phone-mockup-screw">
              <div></div>
            </div>

            <div className="phone-mockup-charger"></div>

            <div className="phone-mockup-screw right">
              <div></div>
            </div>

            <div className="bottom-speaker right">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

            <div className="phone-mockup-antena right"></div>
          </div>
        </div>

        <div className="phone-mockup-screen"></div>

        <div className="phone-mockup-display">
          <div className="phone-mockup-notch">
            <div className="phone-mockup-speaker"></div>
          </div>
          <div className="display-content">
            <div className="container">
              <section className="top-bar">quadra.engenharia</section>

              <section className="stats">
                <div
                  className="stats__img-holder"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../../../assets/phone-mockup/novo-instagram-resized.webp") +
                      ")",
                  }}
                />

                <div className="stats__data">
                  <div className="stats__data__point">
                    <div className="stats__data__point__value">
                      {publications}
                    </div>
                    <div className="stats__data__point__description">
                      Publicações
                    </div>
                  </div>

                  <div className="stats__data__point">
                    <div className="stats__data__point__value">{followers}</div>
                    <div className="stats__data__point__description">
                      Seguidores
                    </div>
                  </div>

                  <div className="stats__data__point">
                    <div className="stats__data__point__value">{following}</div>
                    <div className="stats__data__point__description">
                      Seguindo
                    </div>
                  </div>
                </div>
              </section>

              <section className="description">
                <h2 className="decription__title">Quadra Engenharia</h2>
                <p>A cada quadra uma Belém + bonita.</p>
                <a href="https://quadra.com.vc">quadra.com.vc</a>
              </section>

              <section className="actions">
                <a
                  href="https://instagram.com/quadra.engenharia"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  <button className="actions__btn actions__btn--active">
                    Seguir
                  </button>
                </a>
                <button className="actions__btn">Mensagem</button>

                <button className="actions__btn actions__btn--icon">
                  <i className="fas fa-chevron-down"></i>
                </button>
              </section>

              <section className="stories">
                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/garden.jpg") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">Garden</p>
                </div>
                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner life"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">LIFE</p>
                </div>

                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner il-palagio"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">IL PALAGIO</p>
                </div>

                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner livello"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">LIVELLO</p>
                </div>

                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner piazza"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">PIAZZA</p>
                </div>

                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner living"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">LIVING</p>
                </div>

                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner qbc"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">QBC</p>
                </div>

                <div className="stories__single">
                  <div className="stories__single__img-holder">
                    <div
                      className="stories__single__img-holder__inner art"
                      style={{
                        backgroundImage:
                          "url(" +
                          require("../../../../assets/phone-mockup/stories/sprites.sprite.png") +
                          ")",
                      }}
                    ></div>
                  </div>

                  <p className="stories__single__description">ART</p>
                </div>
              </section>

              <section className="tabs">
                <button className="tabs__btn tabs__btn--active">
                  <img
                    className="tabs__btn__icon"
                    src={icon1}
                    alt="grid icon"
                  />
                </button>

                <button className="tabs__btn">
                  <img
                    className="tabs__btn__icon"
                    src={icon2}
                    alt="igtv icon"
                  />
                </button>

                <button className="tabs__btn">
                  <img
                    className="tabs__btn__icon"
                    src={icon3}
                    alt="tagged icon"
                  />
                </button>
              </section>

              <section className="grid">
                {images?.map((image, index) => (
                  <img
                    key={index}
                    alt="instagram fotos"
                    className="grid__photo"
                    src={image}
                    loading="lazy"
                  />
                ))}
              </section>
            </div>
          </div>
        </div>
        <div className="phone-mockup-reflections">
          <div className="reflection-1"></div>
          <div className="reflection-2"></div>
          <div className="reflection-3"></div>
          <div className="reflection-4"></div>
          <div className="reflection-5"></div>
          <div className="reflection-6"></div>
          <div className="reflection-7"></div>
        </div>
      </div>
    </PhoneContainer>
  );
}

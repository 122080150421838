import logoLivingImg from "../../assets/logos/logo-living.svg";
import quadraLogo from "../../assets/logos/logo-quadra.svg";
import blueQuadraLogo from "../../assets/logos/logo-quadra-blue.svg";
import searchIcon from "../../assets/icons/search.png";
import ScrollAnimation from "react-animate-on-scroll";
import { useEffect, useState } from "react";
import { Hero, Menu } from "./styles";
import { ResponsiveNavMenu } from "../ResponsiveMenu";
import { useFetchByContrato } from "../../services/useFetchByContrato";

export type navProps = {
  navLinks: navLink[];
};

export type navLink = {
  link: string;
  slug: string;
};

export function Header() {
  const [isScrollOnTop, setIsScrollOnTop] = useState<boolean>(true);

  const { data } = useFetchByContrato<navProps>("/nav");

  useEffect(() => {
    window.onscroll = () =>
      window.pageYOffset <= 50
        ? setIsScrollOnTop(true)
        : setIsScrollOnTop(false);
  });

  return (
    <>
      <Menu isScrollOnTop={isScrollOnTop}>
        <div className="grid-container">
          <div className="inside-grid-container">
            <div className="flex-container">
              <div className="menu-background" />
              <div className="logo-container">
                {isScrollOnTop ? (
                  <img src={quadraLogo} className="logo-quadra" />
                ) : (
                  <img src={blueQuadraLogo} className="logo-quadra-blue" />
                )}
              </div>
              <div className="nav-buttons-container">
                <div className="nav-links-container">
                  {data?.navLinks?.map((navLink: navLink) => (
                    <a
                      href={navLink.slug}
                      className="nav-link"
                      key={navLink.link}
                    >
                      {navLink.link}
                    </a>
                  ))}
                </div>
              </div>
              <ResponsiveNavMenu isScrollOnTop={isScrollOnTop} />
            </div>
          </div>
        </div>
      </Menu>
      <Hero>
        <div className="text-center">
          <div className="container-logo">
            <img src={logoLivingImg} alt="Living" />
          </div>
        </div>
        <div className="container-content">
          <div>
            <ScrollAnimation animateIn="animate__fadeIn">
              <h2>Lux Design living</h2>
            </ScrollAnimation>
            <div className="ping-element">
              <svg viewBox="0 0 14.683 22.025">
                <path
                  id="location"
                  d="M16.342,24.077c.534,0,1.81-1.452,2.95-4.053a22.767,22.767,0,0,0,1.944-7.752,5.126,5.126,0,0,0-4.894-5.326,5.126,5.126,0,0,0-4.894,5.326,22.772,22.772,0,0,0,1.944,7.751C14.531,22.625,15.807,24.077,16.342,24.077Zm0,2.447C12.287,26.525,9,16.567,9,12.273A7.566,7.566,0,0,1,16.342,4.5a7.566,7.566,0,0,1,7.342,7.773C23.683,16.566,20.4,26.525,16.342,26.525Zm0-12.236a2.447,2.447,0,1,0-2.447-2.447A2.447,2.447,0,0,0,16.342,14.289Z"
                  transform="translate(-9 -4.5)"
                  fill="#fff"
                />
              </svg>
            </div>
            <p className="container-content-description">
              Localizado no Umarizal, na Av. Pedro Álvares Cabral.
            </p>
          </div>
        </div>
      </Hero>
    </>
  );
}

import { useFetchByContrato } from "../../../../services/useFetchByContrato";
import { ThirdSectionContainer } from "./styles";

type FooterLinks = {
  navLinks: NavLink[];
};

type NavLink = {
  Empreendimentos?: link[];
  Links?: link[];
};

type link = {
  title: string;
  slug: string;
};

export function ThirdSection() {
  const { data } = useFetchByContrato<FooterLinks>("/footer");

  const empreendimentos = data?.navLinks[0].Empreendimentos;
  const links = data?.navLinks[1].Links;

  console.log(empreendimentos);

  return (
    <ThirdSectionContainer>
      <div className="footer-container">
        <div className="third-section-content-container">
          <div className="footer-links-container">
            <div className="columns-container">
              {/*  <div className="column-container">
                <h1>EMPREENDIMENTOS</h1>
                <div className="links-container">
                  {empreendimentos?.map((link) => (
                    <a href={link.slug}>{link.title}</a>
                  ))}
                </div>
              </div> */}
              <div className="column-container">
                <h4>LINKS</h4>
                <div className="links-container">
                  {links?.map((link) => (
                    <a href={link.slug}>{link.title}</a>
                  ))}
                </div>
              </div>
              <div className="column-container">
                <div>
                  <div className="links-container">
                    <h4>SEDE</h4>
                    <p>Av. Brás de Aguiar, 487</p>
                    <p className="">Nazaré</p>
                    <p className="">(91) 31841-100</p>
                  </div>
                  <div className="links-container">
                    <h4 className="">QUADRA STORE UMARIZAL</h4>
                    <p className="">Pedro Alvares Cabral, SN</p>
                    <p className="">(Esq. com Soares Carneiro)</p>
                  </div>
                  <div className="links-container">
                    <h4 className="">QUADRA STORE DOCA</h4>
                    <p className="">Av. Visc. de Souza Franca, 555</p>
                    <p className="">Umarizal</p>
                  </div>
                </div>
              </div>
              {/*   <div className='flex flex-col space-y-5'>
                                <div className="flex flex-col text-center lg:text-left">
                                    <h2 className='  text-brand-gray-600 text-2xl'>SEDE</h2>
                                    <div className="flex flex-col text-center lg:text-left">
                                        <a className='no-underline text-white cursor-text' >Av. Brás de Aguiar, 487</a>
                                        <a className='no-underline text-white cursor-text' >Nazaré</a>
                                        <a className='no-underline text-white cursor-text' >(91) 31841-100</a>
                                    </div>
                                </div>
                                <div className="flex flex-col text-center lg:text-left">
                                    <h2 className='  text-brand-gray-600 text-2xl'>QUADRA STORE UMARIZAL</h2>
                                    <div className="flex flex-col text-center lg:text-left">
                                        <a className='no-underline text-white cursor-text' >Pedro Alvares Cabral, SN</a>
                                        <a className='no-underline text-white cursor-text' >(Esq. com Soares Carneiro)</a>

                                    </div>
                                </div>
                                <div className="flex flex-col text-center lg:text-left">
                                    <h2 className='  text-brand-gray-600 text-2xl'>QUADRA STORE DOCA</h2>
                                    <div className="flex flex-col text-center lg:text-left">
                                        <a className='no-underline text-white cursor-text' >Av. Visc. de Souza Franca, 555</a>
                                        <a className='no-underline text-white cursor-text' >Umarizal</a>

                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="footer-text-container">
          <span>
            Quadra Engenharia. Todos os direitos reservados © 2024. Desenvolvido
            por <a href="https://www.bredi.com.br/">Bredi.</a>
          </span>
        </div>
      </div>
    </ThirdSectionContainer>
  );
}

import barbecue from "../../assets/comodidades/barbecue.svg";
import deckChair from "../../assets/comodidades/deck-chair.svg";
import espacoGourmet from "../../assets/comodidades/espaco-gourmet.svg";
import gym from "../../assets/comodidades/gym.svg";
import loungePub from "../../assets/comodidades/lounge-pub.svg";
import playground from "../../assets/comodidades/playground.svg";
import spa from "../../assets/comodidades/spa.svg";
import swimmingPool from "../../assets/comodidades/swimming-pool.svg";

export const comodidades = [
  {
    id: 1,
    comodidadeImg: swimmingPool,
    textSup: "Piscina com borda infinita",
    textSub: "e raia semiolímpica",
  },
  {
    id: 2,
    comodidadeImg: deckChair,
    textSup: "Deck Solarium",
    textSub: "",
  },
  {
    id: 3,
    comodidadeImg: barbecue,
    textSup: "Churrasqueira Gourmet",
    textSub: "",
  },
  {
    id: 4,
    comodidadeImg: playground,
    textSup: "Playground com lounge",
    textSub: "",
  },
  {
    id: 5,
    comodidadeImg: spa,
    textSup: "SPA ",
    textSub: "com sauna e hidromassagem",
  },
  {
    id: 6,
    comodidadeImg: gym,
    textSup: "Fitness",
    textSub: "com vista para área de lazer",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoGourmet,
    textSup: "Salão de festa com Espaço Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: loungePub,
    textSup: "Espaço gourmet",
    textSub: "",
  },
  /* {
    id: Math.random(),
    comodidadeImg: mountainBike,
    textSup: "Bicicletário",
    textSub: "",
  },

  {
    id: 5,
    comodidadeImg: fingerprint,
    textSup: "Fechadura Digital",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: securityGuard,
    textSup: "Guarita Blindada",
    textSub: "",
  },

  {
    id: 7,
    comodidadeImg: sauna,
    textSup: "Sauna ",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: usb,
    textSup: "Tomadas USB",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: wheelchair,
    textSup: "Elevador PCD",
    textSub: "para acesso à piscina",
  },
  {
    id: 4,
    comodidadeImg: energy,
    textSup: "Gerador Total",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: coworking,
    textSup: "Coworking",
    textSub: "",
  },

  {
    id: Math.random(),
    comodidadeImg: loungePub,
    textSup: "Lounge Pub",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: petCare,
    textSup: "Pet Care",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: petPlace,
    textSup: "Pet Place",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaoFestas,
    textSup: "Salão de Festas",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaoJogoInfantis,
    textSup: "Salão de Jogos",
    textSub: "Infantil",
  },
  {
    id: Math.random(),
    comodidadeImg: salaJogos,
    textSup: "Salão de Jogos",
    textSub: "Adulto",
  },
  {
    id: Math.random(),
    comodidadeImg: salaSpining,
    textSup: "Spinning",
    textSub: "",
  },

  {
    id: Math.random(),
    comodidadeImg: salaoBeleza,
    textSup: "Salão de Beleza",
    textSub: "",
  },

  {
    id: Math.random(),
    comodidadeImg: sistemaAgua,
    textSup: "Sistema de reutilização da água da chuva",
    textSub: "",
  }, */
  // {
  //   id: Math.random(),
  //   comodidadeImg: circuitoSeguranca,
  //   textSup: "Circuito de Segurança",
  //   textSub: "Alta Tecnologia",
  // },
  /*  {
    id: Math.random(),
    comodidadeImg: wifi,
    textSup: "Wifi nas Áreas Sociais",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: energiaSolar,
    textSup: "Sistema de Captação de Energia Solar",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoKids,
    textSup: "Espaço Kids",
    textSub: "",
  }, */
  // {
  //   id: Math.random(),
  //   comodidadeImg: paredeVentilada,
  //   textSup: "Parede Ventilada",
  //   textSub: "",
  // },
  // {
  //   id: Math.random(),
  //   comodidadeImg: sacadaGourmet,
  //   textSup: "Sacada gourmet",
  //   textSub: "",
  // },
];

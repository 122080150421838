import styled from "styled-components";

export const PhoneContainer = styled.div`
  .phone-mockup {
    position: relative;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    transform: translateY(132px) translateX(134px) rotate(343deg) skewX(17deg)
      scale(0.8);

    &:hover {
      transform: translateY(0) translateX(134px) rotate(343deg) skewX(17deg)
        scale(0.8);

      .phone-mockup-back {
        box-shadow: -63px 77px 8px -10px rgba(#000, 30%),
          -67px 92px 19px 0 rgba(#000, 20%),
          -85px 129px 37px -9px rgba(#000, 30%),
          -53px 86px 36px 0 rgba(#000, 10%), -41px 132px 8px 0 rgba(#000, 10%),
          inset 20px 0 15px -6px rgba(#000, 34%),
          inset 2px 0 4px -2px rgba(#000, 60%),
          inset 2px 0 1px -2px rgba(#fff, 10%),
          inset 0 -15px 10px -10px rgba(#fff, 15%);
      }
    }

    .phone-mockup-back {
      position: absolute;
      top: -3px;
      left: 0;
      -webkit-transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -ms-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      transform: translate(-50%, -50%);
      height: 823px;
      width: 414px;
      background-image: linear-gradient(to bottom, #3a3b3f 70%, #1d1d1f);
      border-radius: 74px 40px 84px 50px;
      box-shadow: -26px 8px 8px -10px rgba(0, 0, 0, 0.3),
        -6px 6px 6px 0 rgba(0, 0, 0, 0.6),
        -30px 14px 10px -10px rgba(0, 0, 0, 0.5),
        -20px 40px 8px 0 rgba(0, 0, 0, 0.1), -20px 30px 8px 0 rgba(0, 0, 0, 0.1),
        inset 20px 0 15px -6px rgba(0, 0, 0, 0.6),
        inset 2px 0 4px -2px rgba(0, 0, 0, 0.6),
        inset 2px 0 1px -2px rgba(255, 255, 255, 0.1),
        inset 0 -15px 10px -10px rgba(255, 255, 255, 0.15);

      &:before {
        content: "";
        display: block;
        height: 300px;
        width: 100px;
        position: absolute;
        background-color: #fff;
        left: 16px;
        bottom: 40px;
        border-radius: 20px;
        filter: blur(5px);
        opacity: 0.1;
        transform: rotate(4deg);
      }

      &:after {
        content: "";
        display: block;
        height: 300px;
        width: 100px;
        position: absolute;
        background-color: #fff;
        left: 26px;
        bottom: 40px;
        border-radius: 20px;
        filter: blur(4px);
        opacity: 0.25;
        transform: rotate(2deg);
      }
    }

    .phone-mockup-screen {
      position: absolute;
      top: -16px;
      left: 14px;
      transform: translate(-50%, -50%);
      height: 793px;
      width: 400px;
      background-color: #000;
      border-radius: 48px 40px 52px 45px;
      box-shadow: -2px 0 0px -1px rgba(255, 255, 255, 0.4),
        -1px 3px 3px -3px rgba(255, 255, 255, 0.7);
    }

    .phone-mockup-display {
      position: absolute;
      z-index: 1;
      top: -23px;
      left: 20px;
      transform: translate(-50%, -50%);
      height: 775px;
      width: 378px;
      background-color: #fbfbfb;
      border-radius: 45px 37px 41px 41px;
      overflow: hidden;
    }

    .phone-mockup-notch {
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translate(-50%);
      width: 210px;
      background-color: #131313;
      border-top: 2px solid #000;
      height: 21px;
      z-index: 3;
      border-radius: 0 0 20px 20px;

      &:after {
        content: "";
        display: block;
        height: 12px;
        width: 12px;
        background-color: transparent;
        position: absolute;
        left: -12px;
        top: -5px;
        border-radius: 50%;
        box-shadow: 5px -5px 0 -3px #000;
      }

      &:before {
        content: "";
        display: block;
        height: 12px;
        width: 12px;
        background-color: transparent;
        position: absolute;
        right: -12px;
        top: -5px;
        border-radius: 50%;
        box-shadow: -4px -4px 0 -3px #000;
      }
    }

    .phone-mockup-speaker {
      position: absolute;
      top: 6px;
      width: 58px;
      height: 6px;
      border-radius: 20px;
      background-color: #2b2b2b;
      overflow: hidden;
      box-shadow: 0 0 0 1px #363637, inset -3px 3px 1px 0px #000,
        inset -3px 4px 0px -1px rgba(255, 255, 255, 0.5);
      left: 50%;
      transform: translateX(-50%);

      &:after {
        content: "";
        display: block;
        height: 4px;
        width: 4px;
        background-color: #87888c;
        position: absolute;
        right: -1px;
        border-radius: 50%;
        top: -1px;
      }

      &:before {
        content: "";
        display: block;
        height: 4px;
        width: 4px;
        background-color: #898a8e;
        position: absolute;
        right: -2px;
        border-radius: 50%;
        top: 4px;
      }
    }

    .phone-mockup-left-side {
      position: absolute;
      left: 0;
      top: 60px;
      width: 30px;
      height: 100%;

      .phone-mockup-antena {
        position: absolute;
        height: 20px;
        width: 50px;
        left: -5px;
        top: 10px;
        border-radius: 50%;
        transform: rotate(-40deg) skewX(20deg);
        background-color: transparent;
        box-shadow: 0px -2px 1px -2px #000;

        &.bottom {
          top: auto;
          bottom: 128px;
        }

        &:after {
          content: "";
          display: block;
          height: 20px;
          width: 50px;
          position: absolute;
          transform: rotate(-2deg);
          left: -5px;
          top: 3px;
          border-radius: 50%;
          background-color: transparent;
          box-shadow: 0px -2px 1px -2px #000;
        }
      }
    }

    .phone-mockup-button {
      position: absolute;
      top: 100px;
      left: 4px;
      height: 47px;
      width: 12px;
      background-color: #010101;
      border-radius: 30px;
      transform: skewY(-40deg);
      box-shadow: -32px -16px 10px 0 rgba(0, 0, 0, 0.4),
        -4px 0 4px 0 rgba(0, 0, 0, 0.4);

      &.bottom {
        top: 160px;
      }

      &.top {
        top: 48px;
        height: 29px;
        width: 14px;
        left: 2px;
        border-radius: 10px 10px 10px 3px;
        box-shadow: inset 2px 0 0 -1px rgba(255, 255, 255, 0.25);

        &:after {
          height: 27px;
          width: 8px;
          left: 4px;
          top: 0px;
        }

        &:before {
          height: 28px;
          width: 8px;
          left: 6px;
          top: 0px;
          box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.4);
        }
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 45px;
        width: 10px;
        background-image: linear-gradient(to bottom, #6a6b6f 85%, #0b0b0b);
        border-radius: 20px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -2px;
        top: -1px;
        height: 44px;
        width: 10px;
        background-color: #2d2d2f;
        border-radius: 20px;
        box-shadow: inset -1px -1px 0 -1px rgba(255, 255, 255, 0.6);
      }
    }

    .phone-mockup-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .bottom-speaker {
        position: absolute;
        display: flex;
        left: calc(50% - 120px);
        bottom: 6px;
        transform: skewX(-50deg);

        &.right {
          left: calc(50% + 45px);
        }

        div {
          height: 6px;
          width: 6px;
          background-color: #212121;
          box-shadow: inset -4px -3px 2px -2px #000,
            1px -1px 0 -1px rgba(255, 255, 255, 0.5);
          margin: 0 3px;
          border-radius: 50%;
          position: relative;
          overflow: hidden;

          &:after {
            content: "";
            display: block;
            height: 3px;
            width: 3px;
            background-color: #fff;
            border-radius: 50%;
            position: absolute;
            bottom: -1px;
            left: 3px;
            opacity: 0.4;
          }
        }
      }

      .phone-mockup-screw {
        position: absolute;
        bottom: 5px;
        left: calc(50% - 38px);
        height: 9px;
        width: 9px;
        background-image: linear-gradient(
          to right,
          #1a1a1c 30%,
          #a1a2a7 60%,
          #1a1a1c 80%
        );
        border-radius: 50%;
        transform: translateX(-50%) skewX(-50deg);

        &.right {
          left: calc(50% + 36px);
        }

        div {
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background-color: #252527;
          box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.9),
            inset -2px 1px 1px -1px #000;
          position: relative;

          &:after {
            content: "";
            display: block;
            height: 4px;
            width: 4px;
            background-color: #151515;
            transform: rotate(45deg);
            position: absolute;
            top: 1px;
            left: 0;
            box-shadow: inset -1px -1px 0 0px rgba(255, 255, 255, 0.6);
          }
        }
      }

      .phone-mockup-charger {
        position: absolute;
        left: 50%;
        bottom: 2px;
        background-image: linear-gradient(
          to right,
          #121214 5%,
          #434446 20%,
          #434446 60%,
          #7a7b80 75%,
          #1b1b1d 90%
        );
        transform: translateX(-50%) skewX(-50deg);
        height: 14px;
        width: 48px;
        overflow: hidden;
        border-radius: 15px 16px 24px 20px;
        box-shadow: 1px 1px 1px -1px rgba(255, 255, 255, 0.4),
          inset -2px -2px 0 -1px #0e0e0e, inset -3px -3px 0 -1px #252527,
          inset 3px 3px 0 -1px #050505;

        &:after {
          content: "";
          display: block;
          height: 10px;
          width: 42px;
          background-color: #050505;
          border-radius: 20px 8px 16px 10px;
          box-shadow: 1px 1px 0 -1px rgba(255, 255, 255, 0.4);
        }
      }

      .phone-mockup-antena {
        position: absolute;
        height: 20px;
        width: 50px;
        bottom: 8px;
        left: 60px;
        border-radius: 40%;
        opacity: 0.7;
        transform: rotate(-45deg) skewX(20deg) scaleY(-1);
        background-color: transparent;
        box-shadow: 0px -2px 1px -2px #000;

        &.right {
          left: auto;
          right: 70px;
          box-shadow: 0px -2px 1px -2px #000,
            19px -2px 0 -2px rgba(255, 255, 255, 0.4);

          &:after {
            box-shadow: 0px -2px 1px -2px #000,
              19px -2px 0 -2px rgba(255, 255, 255, 0.4);
          }
        }

        &:after {
          content: "";
          display: block;
          height: 20px;
          width: 50px;
          position: absolute;
          transform: rotate(-2deg);
          left: -3px;
          top: 3px;
          border-radius: 40%;
          background-color: transparent;
          box-shadow: 0px -2px 1px -2px #000;
        }
      }
    }

    .phone-mockup-reflections {
      position: absolute;
      top: -17px;
      left: 15px;
      transform: translate(-50%, -50%);
      height: 795px;
      width: 399px;
      border-radius: 48px 40px 52px 45px;
      background-color: transparent;
      z-index: 4;
      overflow: hidden;
      pointer-events: none;

      .reflection-1 {
        position: absolute;
        height: 200%;
        width: 120%;
        background-color: #fff;
        opacity: 0.1;
        left: -320px;
        top: -200px;
        transform: rotate(32deg);
        z-index: 10;
      }

      .reflection-2 {
        position: absolute;
        height: 100%;
        left: -94px;
        width: 100px;
        border-radius: 100px;
        background-color: #000;
        top: calc(50% + 14px);
        transform: translateY(-50%);
        z-index: 11;
      }

      .reflection-3 {
        position: absolute;
        height: 100%;
        left: -94px;
        width: 100px;
        border-radius: 100px;
        background-color: #000;
        top: calc(50% + 14px);
        transform: translateY(-50%);
        z-index: 11;
        background: #6d6c71;
        left: -8px;
        height: 60%;
        width: 10px;
        opacity: 0.6;
        top: 70%;
      }

      .reflection-4 {
        position: absolute;
        height: 60%;
        top: 0;
        background-color: #67686c;
        width: 16px;

        &:before {
          content: "";
          display: block;
          position: absolute;
          height: 100px;
          width: 100px;
          background-color: transparent;
          left: 16px;
          top: 4px;
          border-radius: 44px 40px 0 0;
          box-shadow: -20px 0px 0 0 #67686c;
        }

        &:after {
          content: "";
          display: block;
          height: 80px;
          width: 80px;
          background-color: transparent;
          position: absolute;
          border-radius: 60px 0 0 0;
          left: 0px;
          top: -1px;
          transform: rotate(15deg);
          box-shadow: -25px -3px 2px -8px #000;
        }
      }

      .reflection-5 {
        position: absolute;
        height: 50%;
        bottom: 0;
        left: 4px;
        width: 12px;
        background-color: #000;
        border-radius: 50%;
      }

      .reflection-6 {
        position: absolute;
        width: 80px;
        height: 20px;
        background-color: #000;
        border-radius: 50%;
        transform: rotate(40deg);
        bottom: -4px;
        left: -14px;
        z-index: 12;

        &:after {
          content: "";
          display: block;
          position: absolute;
          height: 100px;
          width: 20px;
          background-color: #000;
          right: -17px;
          top: -78px;
          border-radius: 40%;
          transform: rotate(50deg);
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          height: 50px;
          width: 50px;
          background-color: transparent;
          top: -51px;
          left: 7px;
          transform: rotate(-20deg);
          border-radius: 40%;
          box-shadow: 4px 15px 1px -10px #000;
        }
      }
    }

    .reflection-7 {
      position: absolute;
      bottom: -4px;
      left: 10px;
      height: 20px;
      width: 40px;
      border-radius: 20px 0px 6px 10px;
      transform: skewX(60deg) skewY(-10deg) scaleY(-1) scaleX(-1) scale(0.8);
      background-color: #5c5d61;
      z-index: 13;

      &:after {
        content: "";
        display: block;
        height: 20px;
        width: 30px;
        background-color: #5c5d61;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        right: 6px;
        transform: rotate(40deg) skewX(-54deg) skewY(47deg) scaleY(1) scaleX(1);
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 18px;
        width: 15px;
        background-color: #5c5d61;
        left: 6px;
        bottom: -6px;
        border-radius: 2px;
        transform: rotate(253deg) skewX(10deg);
      }
    }

    .display-content {
      position: absolute;
      top: 0;
      left: 0;
      height: 776px;
      width: 378px;
      border-radius: 45px 37px 41px 41px;
      overflow: hidden;
      background-color: #000;
      color: #fff;
    }

    /**
     * Instagram fake page
     */

    .container {
      font-family: "Roboto", sans-serif;
      margin: 0 auto;
      padding-bottom: 50px;
      max-width: 375px;
      width: 100%;
      height: 100%;
      overflow: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;

      button {
        border: 0;
        outline: 0;
        background: transparent;
      }

      a {
        text-decoration: none;
      }

      // custom scrollbar
      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bbbbbb;
        outline: none;
        border-radius: 2px;
      }

      @mixin hide-scrollbar {
        overflow-y: scroll;
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;

        /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
          /* WebKit */
          width: 0;
          height: 0;
        }
      }

      .top-bar {
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: 36px 16px 16px 16px;
        font-weight: 800;
        text-align: center;

        &__title {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          &__text {
            font-size: 18px;
          }

          &__verified-icon {
            margin-left: 4px;
            width: 15px;
            height: 14px;
          }
        }
      }

      .stats {
        padding: 0 15px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 10px;

        &__img-holder {
          background-image: "url('../../../../assets/phone-mockup/novo-instagram-resized.webp')";
          width: 90px;
          height: 90px;
          border-radius: 50%;
          background: center / 100% no-repeat;
        }

        &__data {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-around;
          align-items: center;
          text-align: center;

          &__point {
            font-family: sans-serif;

            &__value {
              font-size: 16px;
              font-weight: 600;
            }

            &__description {
              font-weight: 300;
              font-size: 14px;
              margin-top: 2px;
            }
          }
        }
      }

      .description {
        padding: 0 15px;

        h2 {
          font-size: 18px;
          margin: 0;
          margin-top: 16px;
          padding: 0;
        }

        p {
          margin-top: 4px;
          color: #bfbfbf;
        }

        a {
          margin-top: 2px;
          color: #5d9cdf;
        }
      }

      .actions {
        padding: 0 15px;
        margin-top: 14px;
        display: grid;
        grid-template-columns: 1fr 1fr 34px;
        grid-column-gap: 6px;

        &__btn {
          font-size: 15px;
          border-radius: 4px;
          height: 32px;
          display: block;
          width: 100%;
          color: #fff;

          &--icon {
            display: flex;

            i {
              margin: auto;
              font-size: 16px;
            }
          }

          &--active {
            background: #117ee3;
            border: 1px solid #117ee3;
            color: white;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }

      .stories {
        margin-top: 14px;
        padding: 0 10px;
        white-space: nowrap;
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 0 !important;
        }

        &__single {
          display: inline-block;

          &__img-holder {
            display: inline-block;
            border-radius: 50%;
            padding: 3px;
            position: relative;
            left: 50%;
            transform: translatex(-50%);
            margin-right: 12px;

            &__inner {
              border-radius: 50%;
              width: 60px;
              height: 60px;
              background-image: url("/img/elements/phone-mockup/stories/sprites.sprite.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;

              &.il-palagio {
                background-position: 0 -59px;
              }
              &.life {
                background-position: 0 -120px;
              }
              &.livello {
                background-position: 0 -180px;
              }
              &.living {
                background-position: 0 -240px;
              }
              &.piazza {
                background-position: 0 -300px;
              }
              &.qbc {
                background-position: 0 -360px;
              }
              &.art {
                background-position: 0 0;
              }
            }
          }

          &__description {
            text-align: center;
            font-size: 14px;
            margin-top: 2px;
            color: #fff;
          }
        }
      }

      .tabs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 20px;

        &__btn {
          display: flex;
          padding-top: 6px;
          padding-bottom: 5px;
          border-bottom: 1px solid transparent;

          &__icon {
            margin: auto;
            max-width: 22px;
          }

          &--active {
            border-bottom: 1px solid #444;
          }
        }
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 3px;

        &__photo {
          display: block;
          width: 100%;
          height: calc(375px / 3);
          background: center / cover no-repeat;
        }
      }

      .bottom-bar {
        width: calc(375px + 2px);
        height: 50px;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: 10;
        background: #222222;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &__btn {
          &__icon {
            width: 22px;
            text-align: center;
          }

          &__profile-pic {
            border-radius: 50%;
          }
        }
      }
    }
  }
`;

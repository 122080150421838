import React from "react";
import { Container } from "./styles";


interface PlayVideo {
  imagemCapa: string;
  video?: string;
  idModal?: string;
  textoAlt?: string;
}

export function PlayVideo({
  imagemCapa,
  video,
  idModal,
  textoAlt,
}: PlayVideo) {
  return (
    <Container>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-6 col-12 p-0 m-0">
          <div className="video-modal">
            <img className="img-fluid rounded" src={imagemCapa} alt={textoAlt} />
            <a data-bs-toggle="modal" data-bs-target={`#${idModal}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 97 97">
                <g id="Grupo_26" data-name="Grupo 26" transform="translate(-1362 -1744)">
                  <g id="Icon_feather-play-circle" data-name="Icon feather-play-circle" transform="translate(1363 1745)">
                    <path id="Caminho_25" data-name="Caminho 25" d="M98,50.5A47.5,47.5,0,1,1,50.5,3,47.5,47.5,0,0,1,98,50.5Z" transform="translate(-3 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    <path id="Caminho_26" data-name="Caminho 26" d="M15,12,43.5,31,15,50Z" transform="translate(23 16.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="modal fade" id={`${idModal}`} aria-labelledby={`${idModal}Label`} aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content d-flex justify-content-center align-items-center">
            <video width={"100%"} controls preload="none" poster={imagemCapa}>
              <source src={video} type="video/mp4" />
            </video> 
          </div>
        </div>
      </div>
    </Container>
  );
}
